//import React from "react";
import React, {useState, useEffect} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper'
import axios from 'axios';

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/buttons/button";

import instructorRating from "../images/drivers/instructor-placeholder.png";
import instructorLarge from "../images/drivers/instructor-placeholder-large.png";
import carLarge from "../images/drivers/car-placeholder.png";


/*function authenticate (){
  var search_results;
  axios.post('http://13.246.101.200:8443/login', {
    email: 'public-api@nubee.co.za',
    password: "public_api_user"
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    const data = response.data;
    console.log(data["api_token"]);

    axios.get('https://app-api.nubee.co.za:8443/instructors?page=1', {
      headers: {
        Authorization: 'Bearer ' + data["api_token"]
      }
    }).then(response => {
      search_results = response.data;
      console.log(search_results);
      setResponse(search_results)
    });

  });


  //return search_results;
}*/

function detailView(item, setBio, setCar, setLocation, setPlaceId, setFirstName, setLastName, setProfilePicture, setTransmission)
{
    //alert(item)

    setBio(item.address["bio"]);
    setCar(item.address["carShot"]);
    setLocation(item.address["location"]);
    setPlaceId(item.address["placeId"]);
    setFirstName(item.firstName);
    setLastName(item.lastName);
    setProfilePicture(item.address["headShot"]);
    setTransmission(item.address["carTransmition"])

    localStorage.setItem("selected-instructor", item);

}

const DriversInstructorPage = () => {


    const [response, setResponse] = useState([]);

    const [bio, setBio] = useState("");
    const [car, setCar] = useState(carLarge);
    const [location, setLocation] = useState("");
    const [placeId, setPlaceId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profilePicture, setProfilePicture] = useState(instructorLarge);
    const [transmission, setTransmission] = useState("");
    

/*    useEffect(() => {
        var search_results;
        axios.post('http://13.246.101.200:8443/login', {
            email: 'public-api@nubee.co.za',
            password: "public_api_user"
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            const data = response.data;
            console.log(data["api_token"]);

            axios.get('https://app-api.nubee.co.za:8443/instructors?page=1', {
                headers: {
                    Authorization: 'Bearer ' + data["api_token"]
                }
            }).then(response => {
                search_results = response.data;
                console.log(search_results);
                setResponse(search_results)
            });

        });
    }, []);*/


    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch("https://8qw56u2vfj.execute-api.af-south-1.amazonaws.com/app-beta//instructors");
            const jsonData = await response.json();
            setData(jsonData);
        };

        fetchData();
    }, []);

    /*  if (!response) {
        return <p>Loading...</p>;
      }*/

    return (
        <Layout>
            <SEO
                keywords={[`Nubee`, `driving school`, `learners`, `artificial intelligence`]}
                title="Drivers"
            />

            <section className="md:p-0 text-left pt-28 md:pt-20">
                <div className="px-6 md:max-w-7xl mx-auto">
                    <div className="md:pr-2">
                        <a href="/drivers-vehicle-selection">← Go Back</a>
                        <div>
                            <h1 className="inline-block w-11/12 md:w-3/4 my-4 text-5xl md:text-6xl font-bold text-black font-heading">
                                Lets get you driving with your perfect instructor.
                            </h1>
                            <p className="py-8 text-black font-body max-w-xl">Select an instructor that best suites
                                you.</p>
                        </div>

                        <div>
                            <form>
                                <div className="flex">
                                    <div className="md:mb-3 w-full md:w-96">
                                        <div className="input-group relative flex w-full mb-4">
                                            <input type="search"
                                                   className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                   placeholder="Search" aria-label="Search"
                                                   aria-describedby="button-addon2"></input>
                                            <button
                                                className="btn inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
                                                type="button" id="button-addon2">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                     data-icon="search" className="w-4" role="img"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="currentColor"
                                                          d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div>
                                        <p className="py-6 text-black font-body">Search by area:</p>
                                        <form>
                                            <div className='flex gap-3 text-sm items-center md:w-max flex-wrap'>
                                                <label className='border-2 border-slate-800 py-2 px-6 rounded-lg'>Green Point</label>
                                                <label className='border-2 border-slate-800 py-2 px-6 rounded-lg'>Sea Point</label>
                                                <label className='border-2 border-slate-800 py-2 px-6 rounded-lg'>Claremont</label>
                                                <label className='border-2 border-slate-800 py-2 px-6 rounded-lg'>Stellenbosch</label>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="mt-5">
                            <div className="flex h-full overflow-y-hidden">
                                <div className="flex flex-auto flex-col md:flex-row w-full">
                                    <div className="flex items-center space-x-3 text-white">
                                    </div>
                                    <div
                                        className="flex flex-auto flex-col md:flex-row p-2 md:p-5 md:items-center border-2 border-black">
                                        <div className="flex h-96 flex-auto overflow-y-auto">
                                            <div
                                                className="grid w-full grid-cols-1 mt-10 md:mt-0 gap-6 md:gap-5 md:p-8">

                                                {data ? (
                                                    <div>

                                                    {data.map((item) => (
                                                            <div key={item.id} onClick={()=>detailView(item, setBio, setCar, setLocation, setPlaceId, setFirstName, setLastName, setProfilePicture, setTransmission)}
                                                                 className="flex flex-row justify-between items-center p-2 hover:bg-nubee-purple active:bg-nubee-purple cursor-pointer">
                                                                <img className="w-28 h-28 md:w-16 md:h-16 rounded-full"
                                                                     src={item.address["headShot"]}/>
                                                                <div className="flex flex-col md:flex-row">
                                                                    <p>{item.firstName} {item.lastName}</p>
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                ) : (
                                                    "Loading..."
                                                )}



{/*                                                {response.map((item) => (


                                                    <div key={item.id} onClick={()=>detailView(item, setBio, setCar, setLocation, setPlaceId, setFirstName, setLastName, setProfilePicture, setTransmission)}
                                                         className="flex flex-row justify-between items-center">
                                                        <img className="-28 h-28 md:w-16 md:h-16"
                                                             src={item.address["headShot"]}/>
                                                        <div className="flex flex-col md:flex-row md:gap-32">
                                                            <p>{item.firstName} {item.lastName}</p>
                                                            <div></div>
                                                        </div>
                                                    </div>


                                                ))}*/}


                                            </div>
                                        </div>

                                        {/* side bar */}

                                        <div
                                            className="w-full py-5 md:w-6/12 order-first md:order-last bg-nubee-pastel-yellow">
                                            {/* <img className="w-2/3 mx-auto" src={instructorLarge}/> */}
                                            <div className="w-10/12 md:w-3/5 mx-auto">
                                                <Swiper
                                                    style={{
                                                        "--swiper-navigation-color": "#000",
                                                        "--swiper-navigation-size": "25px",
                                                    }}
                                                    autoplay={{
                                                        delay: 3500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    modules={[Navigation]}
                                                    navigation
                                                    spaceBetween={10}
                                                    slidesPerView={1}
                                                    onSlideChange={() => console.log('slide change')}
                                                    onSwiper={(swiper) => console.log(swiper)}
                                                >

                                                    <SwiperSlide>
                                                        <img className="mx-auto pb-2 w-full md:w-60 md:h-60 rounded-full"
                                                             src={profilePicture}/>
                                                        <div className="text-center md:w-60 mx-auto">
                                                            <h2>{firstName} {lastName}</h2>
                                                            <p className="mt-2 text-sm">{bio}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img className="mx-auto pb-2 w-full md:w-60 md:h-60 rounded-full" src={car}/>
                                                        <div className="text-center md:w-60 mx-auto">
                                                            <h2>Car Transmission: {transmission}</h2>
                                                            <p className="mt-2 text-sm">{location}</p>
                                                        </div>
                                                    </SwiperSlide>
      {/*                                              <SwiperSlide>
                                                        <div className="text-center md:w-60 mx-auto pt-24">
                                                            <p>The process of getting a drivers licence is super
                                                                daunting, until now. John was literally my wingman all
                                                                the way - he helped me pass my drivers with confidence
                                                                and was an awesome driving instructor. Thanks John!</p>
                                                            <p className="mt-2 text-sm">- Nubee Student</p>
                                                        </div>
                                                    </SwiperSlide>*/}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="px-6 md:pr-16 lg:pr-10 xl:pr-20 py-3 md:pt-5">
                <div className="text-right">
                    <Button title="Continue" href="/date-time-selection"></Button>
                </div>
            </section>


        </Layout>
    );
}


export default DriversInstructorPage;
